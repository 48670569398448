// extracted by mini-css-extract-plugin
export var back = "event-module--back--EeYcB";
export var background = "event-module--background--4phh1";
export var banner = "event-module--banner--EaoTk";
export var border = "event-module--border--zG8Fe";
export var date = "event-module--date--52irj";
export var description = "event-module--description--xoTaO";
export var grid = "event-module--grid--ftcca";
export var link = "event-module--link--xilF4";
export var nav = "event-module--nav--DMWko";
export var next = "event-module--next--w-H7A";
export var prev = "event-module--prev--F7jgW";
export var programme = "event-module--programme--BvKiw";
export var subNav = "event-module--subNav--Tqwl9";
export var time = "event-module--time--hIZul";
export var title = "event-module--title--9vG-s";